import { useEffect } from 'react';

const FCKoeln = () => {
  useEffect(() => {
    localStorage.setItem('club_id', 287);
    localStorage.setItem('club_name', '1. FC Köln');

    window.location.assign('/');
  });

  return null;
};
export default FCKoeln;
